import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "about.jpg" }) {
      relativePath
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div className="content-area">
      <main className="site-main">
        <div className="bg-c-1 padd-xs-b60 padd-lg-b60">
          <div className="container padd-only-sm">
            <div className="row">
              <div className="col-xs-12 marg-xs-t40 marg-lg-t60">
                <section className="c-about">
                  <header className="c-about-header">
                    <h3 className="c-heading">
                      <span>About us</span>
                    </h3>
                  </header>
                  <div className="c-about-img">
                    <Img
                      className="img-responsive"
                      fluid={data.file.childImageSharp.fluid}
                    />
                  </div>

                  <div className="c-about-content-with-footer clearfix">
                    <div className="content">
                      <p>
                        Beth, originally from California and Thomas, born and
                        raised outside of Paris, met in Paris many moons ago
                        while Beth was studying abroad. She was teaching an
                        English class "Music in American society" at the
                        university where Thomas was studying computer science,
                        he was a HUGE fan of Bob Dylan and decided to take her
                        class. He joined her class and the rest is history.
                      </p>
                      <p>
                        Beth and Thomas lived for several years in Paris
                        together where Thomas co-created the mobile development
                        agency{" "}
                        <a href="https://www.backelite.com" target="blank">
                          Backelite
                        </a>{" "}
                        and Beth pursued a Master's degree and eventually a PhD
                        in Anthropology/Sociology. Her studies brought her to
                        the tea plantations of southern India, the soccer fields
                        of urban Senegal and back to the bay area where she was
                        a visiting graduate student researcher at UC Berkeley.
                        Thomas joined her for a time in Senegal, working on a
                        connected lamp project and as a mentor in several start
                        ups in Dakar and later in California where he worked
                        with the smart watch companies Pebble and Fitbit.
                      </p>
                      <p>
                        In 2019, Beth completed her PhD. Together they decided
                        to live full time on their 31 foot sailboat Tukki.
                        Tukki, which means to travel (verb) or a voyage (noun)
                        in Wolof ( the most common language in Senegal)
                        exemplifies their shared love for adventure and the
                        spirit of Senegalese teranga (hospitality and love for
                        others) which they have come to hold very dear to their
                        hearts. They set sail from San Francisco and are
                        currently traveling the Pacific coast of Mexico. They
                        will see where the wind and tides of life bring them
                        next.
                      </p>
                      <div className="info-details">
                        <h5 className="details-title">Do get in touch!</h5>
                        <a href="mailto:ahoy@lovethis.world">
                          ahoy@lovethis.world
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="c-about-content-no-footer clearfix">
                    <h5 className="aboutus-title">Philosophy</h5>
                    <p className="content-left">
                      To travel is to share. Or at least that's the way we think
                      it should be. Our philosophy is that travelling isn't just
                      about the personal experience of enrichment, it's about
                      engaging with the world around us - sharing both a piece
                      of ourselves with those we meet and making pieces of them
                      a part of our lives through our joint experience of the
                      sights, sounds and sensations we find together along the
                      way. In this sense, travel is a constant journey, one that
                      extends well beyond the initial destination. Back "home"
                      our world is created from an aggregate of our "travels"
                      and as we share this "home" with others, our experiences
                      shape in turn fragments of their own journey.
                    </p>
                    <p className="content-right">
                      Lovethis.world aims to be a virtual "home" for travelers.
                      Experimenting with different mediums of expression (sound,
                      interviews, music, stories, recommendations, videos,
                      photos, art) we are creating a modern interactive travel
                      blog where travelers come to share pieces of themselves
                      and find inspiration in fragments of others' journey in
                      loving this world we live in.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- main --> */}
    </div>
    {/* <!-- primary --> */}
  </Layout>
)

export default AboutPage
